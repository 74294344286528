import { AppSettings }      from 'src/app/app.settings';
import { HttpClient }       from '@angular/common/http';
import { Room }             from 'src/app/models/room';
import { Injectable }       from '@angular/core';
import { of, Observable }   from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RoomService {

  private readonly URL = AppSettings.API_INSCRIPTIONS;

  constructor( private http: HttpClient) {}

  getRooms(): Observable<any[]> {
    return this.http.get<Room[]>(`${this.URL}/sala/`);
  }

  getById(id): Observable<any[]> {
    return this.http.get<Room[]>(`${this.URL}/sala/${id}`);
  }

  getByProductAndCalendar(product, calendar): Observable<any> {
    return this.http.get<any>(`${this.URL}/sala/product/${product}/calendar/${calendar}`);
  }

  save(room: Room): Observable<any> {
    return this.http.post<any>(`${this.URL}/sala/`, room);
  }

  update(id: number, room: Room): Observable<any> {
    return this.http.put<any>(`${this.URL}/sala/${id}`, room);
  }
}
